import { Poll, PollId } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";

export function poll__client__getPollSubscription(p: { pollId: PollId }): BifrostSubscription<Poll> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const disposeFns: any[] = [];
  const instance = createBifrostSubscription<Poll>({ dispose: () => disposeFns.forEach(fn => fn()) });

  const sub = h.Poll.docSubscription(p.pollId).subscribe(
    p2 => {
      if (p2) {
        instance.nextData(p2);
      }
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

// i18n certified - complete
