import { AccountId, Poll, PollId } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function poll__client__getCreatedPollSubscription(p: { creatorAccountId: AccountId }): BifrostSubscription<Poll[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.Poll.querySubscription({ where: [{ creatorAccountId: ["==", p.creatorAccountId] }, { deletedAtMS: ["==", 0] }] })
  );
}

// i18n certified - complete
