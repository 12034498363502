import { BatchTask } from "@ollie-sports/firebase";
import { AccountId, PollId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function poll__client__saveVotes(p: {
  pollId: PollId;
  accountId: AccountId;
  votes: Record<number, true>;
  notes: Record<number, string>;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const batchTasks: BatchTask[] = [];

  batchTasks.push(
    await h.Poll.setPath(
      {
        id: p.pollId,
        pathObj: { votesByAccountId: { [p.accountId]: true } },
        value: { votesByAccountId: { [p.accountId]: _.cloneDeep(p.votes) } }
      },
      { returnBatchTask: true }
    )
  );

  batchTasks.push(
    await h.Poll.setPath(
      {
        id: p.pollId,
        pathObj: { notesByAccountId: { [p.accountId]: true } },
        value: { notesByAccountId: { [p.accountId]: _.cloneDeep(p.notes) } }
      },
      { returnBatchTask: true }
    )
  );

  await h._BatchRunner.executeBatch(batchTasks);
}

// i18n certified - complete
