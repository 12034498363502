import { PollId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function poll__client__endPoll(p: { pollId: PollId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.Poll.update({ id: p.pollId, doc: { endDateTimeMS: Date.now() } });
}

// i18n certified - complete
