import { Poll, PollId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function poll__client__getPoll(p: { pollId: PollId }): Promise<Poll | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return await h.Poll.getDoc(p.pollId);
}

// i18n certified - complete
