import { BatchTask } from "@ollie-sports/firebase";
import { Message, Poll } from "@ollie-sports/models";
import express from "express";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { schedulePosthook } from "../../internal-utils/schedulePosthook";
import { validateToken, validateUserIdentity } from "../../internal-utils/server-auth";
import { notification__server__triggerForPollReminder } from "../notification";
import { sendNotificationsForNewMessage } from "../notification/message-helpers";

export async function poll__server__createPoll(p: { poll: Omit<Poll, "id" | "createdAtMS"> }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const { serverConfig } = getServerHelpers();

  const pollId = h.Poll.generateId();
  const nowMS = Date.now();

  const messageId = h.Message.generateId();

  const batchTasks: BatchTask[] = [];

  const pollToAdd = {
    ...p.poll,
    ...{
      id: pollId,
      createdAtMS: nowMS
    }
  };

  const newMessage: Message = {
    accountId: p.poll.creatorAccountId,
    id: messageId,
    conversationId: p.poll.conversationId,
    deletedAtMS: 0,
    createdAtMS: nowMS,
    createdAtServerTimestamp: h._MagicServerTimestamp,
    pollId
  };

  batchTasks.push(
    await h.Poll.add(
      {
        doc: _.cloneDeep(pollToAdd)
      },
      { returnBatchTask: true }
    )
  );

  batchTasks.push(
    await h.Message.add(
      {
        doc: newMessage
      },
      { returnBatchTask: true }
    )
  );

  batchTasks.push(
    await h.Conversation.update(
      { id: p.poll.conversationId, doc: { lastMessageId: messageId, updatedAtMS: Date.now() } },
      { returnBatchTask: true }
    )
  );

  await h._BatchRunner.executeBatch(batchTasks);

  await sendNotificationsForNewMessage({ selfAccountId: p.poll.creatorAccountId, messageId });

  const minutes = (p.poll.endDateTimeMS - Date.now()) / (1000 * 60) - 60; // number of minutes until 60 minutes before expiration date

  if (minutes > 0 && p.poll.shouldNotify) {
    schedulePosthook({
      webhookData: {
        data: { pollId }
      },
      bifrostFn: notification__server__triggerForPollReminder,
      minutes,
      apiKey: serverConfig.posthook.apiKey
    });
  }

  return newMessage;
  // SERVER_ONLY_TOGGLE
}

poll__server__createPoll.auth = async (r: express.Request) => {
  await validateUserIdentity({ request: r, propertyToCheck: "poll.creatorAccountId" });
};

// i18n certified - complete
