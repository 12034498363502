import { Conversation, ConversationId, Poll, PollId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function poll__client__getPollsForConversation(p: { conversationId: ConversationId }): Promise<Poll[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const polls = (
    await h.Poll.query({
      where: [{ conversationId: ["==", p.conversationId] }, { deletedAtMS: ["==", 0] }]
    })
  ).docs;

  return polls;
}

// i18n certified - complete
